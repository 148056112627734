<template>
  <div class="home">


    <!-- ------------------------------- Main ------------------------------------------ -->

	<div class="row">
		<div class="col-sm">
			<CCard>
			<CCardBody>
				<h3 class="display-5">Sale Ledger</h3>
				<br>
				<div class="text-left">
					<ul>
						<li>Create, Modify Sale Orders</li><br>
						<li>Sale Order Approval, Dispatch Workflow</li><br>
						<li>Reports - Revenue & Taxes, Daily Sales Cashflow</li><br>
						<li>Add, Modify Customers</li>
					</ul>
				</div>
			</CCardBody>
			</CCard>
		</div>
		<!-- </CCol> -->
		<div class="col-sm">
			<CCard>
			<CCardBody>
				<h3 class="display-5">Purchase Ledger</h3>
				<br>
				<div class="text-left">
					<ul>
						<li>Create, Modify Purchase Orders</li><br>
						<li>Purchase Order Approval, Dispatch Workflow</li><br>
						<li>Reports - Purchase Amount & Taxes, Daily Purchase Cashflow</li><br>
						<li>Add, Modify Suppliers</li>
					</ul>
				</div>
			</CCardBody>
			</CCard>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-3">
		</div>
		<div class="col-sm-6">
			<CCard>
			<CCardBody>
				<h3 class="display-5">Inventory Ledger</h3>
				<br>
				<div class="text-left">
					<ul>
						<li>Track Inventory in Multiple Locations/Business Units</li><br>
						<li>Dashboard - Current Inventory Snapshot</li><br>
						<li>Reports - Sales, Manufacturing, Inventory Value</li><br>
						<li>Locationwise & Itemwise Inventory Entry and Reports</li>
					</ul>
				</div>
			</CCardBody>
			</CCard>
		</div>
		<div class="col-sm-3">
		</div>
	</div>
	
  </div>
</template>

    <!-- ------------------------------------------------------------------------- -->

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader";


export default {
	name: 'home',
	
	data () {
		return {
		}
	},

	computed: {
	},

	methods: {
	},

}
</script>


